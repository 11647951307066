<template>
  <splide
    ref="slider"
    :slides="slides"
    :options="{type: 'loop', height: '100vh', arrows: false, pagination: false, start: actualIndex}"
    @splide:moved="onSlideChange"
    @splide:dragged="evented = false"
    @dblclick="toggleFullscreen"
  >
    <splide-slide v-for="(slide, k) in slides" :key="slide" >
      <component :is="types[slide.type] || types['webpage']" :obj="slide" :is-active="k === actualIndex" />
    </splide-slide>
  </splide>
</template>

<script>
import { markRaw } from 'vue'
import { onIoEvent, ioEmit } from '@/api/io'
import slides from '@/api/slides'
import Image from '@/components/Image'
import Video from '@/components/Video'
import WebPage from '@/components/WebPage'

import Splide from '@/components/vendor/Splide'
import { SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  components: { Splide, SplideSlide },
  data() {
    return {
      index: 0,
      evented: false,
      types: {
        image: markRaw(Image),
        video: markRaw(Video),
        webpage: markRaw(WebPage),
      },
    }
  },
  computed: {
    slides() {
      return slides
    },
    actualIndex() {
      if (slides.length === 0 || this.index < 0) {
        return 0
      } else if (this.index > slides.length - 1) {
        return slides.length - 1
      }
      return this.index
    },
  },
  mounted() {
    onIoEvent('change-index', data => {
      this.evented = true
      this.index = data.newIndex
      if (this.$refs.slider) {
        let control = data.newIndex
        if (data.destIndex < 0 && data.newIndex >= slides.length - 1) {
          control = '-1'
        } else if (data.newIndex === 0 && data.destIndex >= slides.length) {
          control = '+1'
        } else if (data.newIndex > slides.length - 1) {
          control = slides.length - 1
        }
        this.$refs.slider.go(control)
      }
    })
  },
  methods: {
    onSlideChange(slider, newIndex, prevIndex, destIndex) {
      if (newIndex !== prevIndex && !this.evented) {
        this.index = newIndex
        ioEmit('change-index', {newIndex, prevIndex, destIndex, persist: true})
      }
    },
    toggleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        document.body.requestFullscreen().then(() => this.$refs.slider.splide.refresh())
      }
    },
  },
}
</script>

<style>
body {
  background-color: #000;
  color: #fff;
  margin: 0;
  overflow: hidden;
}
img, video, iframe {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
</style>
