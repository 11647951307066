import { reactive } from 'vue'
import { displayId } from '@/store'
import { onIoEvent } from './io'

let initialized = false
const slides = reactive([])

export function initSlides() {
  if (initialized) {
    console.warn('Slides already initialized')
    return
  }
  onIoEvent('slides-updated', changes => {
    const deleted = changes.deleted || []
    deleted.forEach(doc => {
      if (doc.displayId && doc.displayId != displayId) {
        return
      }
      const slideIdx = slides.findIndex(slide => slide._id === doc._id)
      if (slideIdx >= 0) {
        slides.splice(slideIdx, 1)
      }
    })
    const updated = changes.updated || []
    updated.forEach(doc => {
      if (doc.displayId != displayId) {
        return
      }
      const slideIdx = slides.findIndex(slide => slide._id === doc._id)
      if (slideIdx >= 0) {
        slides[slideIdx] = doc
      } else {
        slides.push(doc)
      }
    })
    if (updated.length) {
      slides.sort((a, b) => a.position - b.position)
    }
  })
}

export default slides
