<template>
  <video playsinline ref="videoEl" :loop="!!obj.loop" :src="obj.url" />
</template>

<script>
export default {
  props: ['obj', 'isActive'],

  mounted() {
    if (this.isActive) {
      this.play()
    }
  },
  watch: {
    isActive(active) {
      if (active) {
        this.play()
      } else {
        this.stop()
      }
    }
  },
  methods: {
    play() {
      const videoEl = this.$refs.videoEl
      videoEl.muted = false
      const promise = videoEl.play()
      if (promise) {
        promise.catch(() => {
          videoEl.muted = true
          videoEl.play()
        })
      }
    },
    stop() {
      const videoEl = this.$refs.videoEl
      videoEl.pause()
      videoEl.fastSeek(0)
    },
  }
}
</script>
