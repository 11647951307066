<template>
  <div class="webpage-container">
    <iframe :src="obj.url" frameborder="0"></iframe>
    <div class="webpage-overlay" v-if="!obj.interactive" />
  </div>
</template>

<script>
export default {
  props: ['obj']
}
</script>

<style scoped>
.webpage-container {
  position: relative;
  height: 100%;
}
.webpage-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
