import { ref, watch } from 'vue'
import io from 'socket.io-client'

let initialized = false
const socket = ref(null)
const pending = {
  events: [],
  emits: [],
}

export function initIo(url) {
  if (initialized) {
    console.warn('IO already initialized')
    return
  }
  initialized = true
  socket.value = io(url)
  socket.value.on('app-update', () => {
    location.reload(true)
  })
}

export function onIoEvent(event, cb) {
  if (socket.value) {
    socket.value.on(event, cb)
  } else {
    pending.events.push({event, cb})
  }
}

export function ioEmit(event, data) {
  if (socket.value) {
    socket.value.emit(event, data)
  } else {
    pending.emits.push({event, data})
  }
}

watch(socket, () => {
  if (socket.value) {
    pending.events.forEach(e => socket.value.on(e.event, e.cb))
    pending.emits.forEach(e => socket.value.emit(e.event, e.data))
    pending.events = []
    pending.emits = []
  }
})
