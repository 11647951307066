import axios from 'axios'
import { displayId } from '@/store'
import { initSlides } from './slides'
import { initIo } from './io'


(async () => {
  const {data: init} = await axios.get(process.env.VUE_APP_INIT_URL, {params: {displayId}})
  initSlides()
  initIo(init.io)
})()
